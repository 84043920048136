import React from "react"
import ComingSoon from '../../components/coming-soon'
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const DebtCollection = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner12@2x.jpg'),
    heading: "When dunning isn't enough, we're on your side",
    subheading: "Recover revenue with advanced dunning tools and if that doesn't work - in select countries - get us to handle the debt for you with a series of letter, phone calls and - where appropriate - instructing collection agencies. A success fee applies on top of the Billsby Pro fee for collections services."
  }

  return (
    <Layout>
      <SEO 
        title="Debt Collection | Billsby Pro | Take your subscription business to the next level" 
        description="Debt Collection and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/debt-collection"
      />

      <ComingSoon 
          backgroundImage={comingSoonDetails.backgroundImage}
          heading={comingSoonDetails.heading}
          subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default DebtCollection